<script>
import { slide } from 'svelte/transition'
import { onMount } from 'svelte'

let item = null

async function receivedNewItem (e) {
  item = e.detail
  setTimeout(() => {
    if (item === e.detail) {
      item = null
    }
  }, 5000)
}

onMount(() => {
  document.addEventListener('cart-add', receivedNewItem)
  return () => document.removeEventListener('cart-add', receivedNewItem)
})
</script>

{#if item}
<div id="toaster">
  <p transition:slide>
    <span>Added “{ item.name }”</span>
    <a href="/cart/">View Cart ❯</a>
  </p>
</div>
{/if}

<style>
#toaster {
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1em;
  position: fixed;
  z-index: 100;
}
#toaster a {
  margin-left: auto;
  white-space: nowrap;
}
#toaster > p {
  align-items: center;
  background: #eee;
  display: flex;
  flex-wrap: wrap;
  margin: 1em auto;
  padding: 1em;
}
</style>
