<script>
import Toaster from './toaster.svelte'
import cart from '$src/store/cart.js'
</script>

<div class="cart" class:empty={ !$cart.length }>
  <a href="/cart">
    <img alt="Cart" src="/icon/cart.svg" />
    <span class="number">{ $cart.length }</span>
  </a>
</div>

<Toaster />

<style>
a {
  display: block;
  padding: .5em;
}
img {
  height: 2em;
  width: 2em;
  vertical-align: middle;
}
.cart {
  position: absolute;
  right: 1em;
  top: 0;
}
.number {
  background: #333333;
  border-radius: .8em;
  color: white;
  font-size: 80%;
  padding: 0 .4em;
  position: absolute;
  right: .5em;
  top: .5em;
}

@media (max-width: 35em) {
  .cart.empty {
    display: none;
  }
}
</style>
